import React from "react";
import { ISkill } from "./Skills";
import { Rating } from 'primereact/rating';

export interface ISkillDescriptionProps {
    selectedSkill: number;
    skills: ISkill[];
}

export default class SkillDescription extends React.Component<ISkillDescriptionProps, {}> {

    public render() {
        return(
            <div className="skill-description">
                {this.props.skills[this.props.selectedSkill] && 
                    <>
                        <h3>{this.props.skills[this.props.selectedSkill].title}</h3>
                        <div className="skill-description-rating"><span>Skill Level: </span><Rating value={this.props.skills[this.props.selectedSkill].rating || 0} readOnly stars={5} cancel={false} /></div>
                        <p>{this.props.skills[this.props.selectedSkill].subtitle}</p>

                        {this.props.skills[this.props.selectedSkill].experience && this.props.skills[this.props.selectedSkill].experience.length > 0 &&
                            <>
                                <p>Erfahrung mit:</p>
                                <ul>
                                    {this.props.skills[this.props.selectedSkill].experience.map(x => <li key={x}>{x}</li>)}
                                </ul>
                            </>
                        }
                    </>
                }
            </div>
        );
    }

}