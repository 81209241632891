import React from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Tag } from 'primereact/tag';

export interface IExperienceState {
    selectedTab: number;
}

const tabElements: JSX.Element[] = [
    <AccordionTab header="Datawarehouse" className="experience-accordion-tab" key="Datawarehouse">
        <div className="experience-accordion-tab-tags">
            <Tag>Azure Data Factory</Tag>
            <Tag>Azure DevOps Pipelines</Tag>
            <Tag>Azure Storage Account</Tag>
            <Tag>Azure Data Lake</Tag>
            <Tag>Azure SQL</Tag>
            <Tag>Azure Functions</Tag>
            <Tag>.NET</Tag>
            <Tag>Cosmos DB</Tag>
            <Tag>Power BI</Tag>
        </div>
        <p>Konzeptionierung und Umsetzung eines Data Warehouse in Microsoft Azure:</p>
        <ul>
            <li>Analyse bestehender Systeme und Daten sowie zukünftiger Erweiterungs- und Entwicklungsmöglichkeiten</li>
            <li>Konzepterstellung für das Sichere Speichern von Daten und die Anbindung der einzelnen Quell- und Zielsysteme aus Cloud und On-Premises</li>
            <li>Umsetzung der Data Warehouse Lösung mit Azure Cloud Ressourcen und Verbindungen zu den bestehenden Systemen</li>
            <li>Migration der vorhandenen Daten aus den vorherigen Datenspeichern</li>
        </ul>
    </AccordionTab>,
    <AccordionTab header="IoT - Automobil" className="experience-accordion-tab" key="IoT - Automobil">
        <div className="experience-accordion-tab-tags">
            <Tag>Azure IoT Hub</Tag>
            <Tag>Azure Event Hub</Tag>
            <Tag>Azure Storage Account</Tag>
            <Tag>Azure SQL</Tag>
            <Tag>Azure Functions</Tag>
            <Tag>Typescript</Tag>
            <Tag>MQTT</Tag>
            <Tag>C / C++</Tag>
            <Tag>Bosch XDK</Tag>
        </div>
        <p>Entwicklung einer IoT Lösung für in Autos verbaute Sensoren:</p>
        <ul>
            <li>Abwägen verschiedener IoT-Lösungskonzepte im Bereich der Azure Cloud</li>
            <li>Automatisierte Einrichtung und Management der IoT-Devices über zentrale Cloud Oberflächen</li>
            <li>Auslesen, Verarbeiten und Speichern der Sensordaten in der Azure Cloud</li>
            <li>Programmieren des Bosch XDK Sensorboards zum Abfragen der Sensoren und versenden der Daten an die Cloud</li>
            <li>Anbinden von externen Sensoren an das Sensorboard</li>
        </ul>
    </AccordionTab>,
    <AccordionTab header="SharePoint Migration" className="experience-accordion-tab accordion-break" key="SharePoint Migration">
        <div className="experience-accordion-tab-tags">
            <Tag>SharePoint</Tag>
            <Tag>ShareGate</Tag>
            <Tag>On-Premises</Tag>
            <Tag>Administration</Tag>
            <Tag>Migration</Tag>
            <Tag>Provisioning-Services</Tag>
            <Tag>Powershell</Tag>
            <Tag>Javascript</Tag>
            <Tag>.NET</Tag>
        </div>
        <p>Migration von hochangepassten On-Premises SharePoint Systemen:</p>
        <ul>
            <li>Analyse der vorhandenen SharePoint Server und Inhalte</li>
            <li>Vorbereitung und Planung der SharePoint Umgebungen inkl. abhängiger Systeme (Active-Directory, Load-Balancer, Database, PowerBI, etc.)</li>
            <li>Migration der vorhandenen SharePoint Inhalte</li>
            <li>Migration von Drittanbieterlösungen</li>
            <li>Neuentwicklungen im Bereich Provisionierung zur automatisierten Erstellung von SharePoint-Seiten auf Basis von kundendefinierten Vorlagen</li>
            <li>Begleitend tiefgreifendere Anpassungen der SharePoint-Umgebung außerhalb der SharePoint Nutzeroberfläche per Powershell, CSOM, PnP und teilweise auf Datenbankebene</li>
        </ul>
    </AccordionTab>,
    <AccordionTab header="Rechnungserstellung" className="experience-accordion-tab" key="Rechnungserstellung">
        <div className="experience-accordion-tab-tags">
            <Tag>Azure Storage Account</Tag>
            <Tag>Azure Functions</Tag>
            <Tag>Typescript</Tag>
            <Tag>Adobe PDF Services</Tag>
            <Tag>Stripe</Tag>
        </div>
        <p>Erstellung von PDF-Rechnungen auf Basis von Buchungsdaten mit Verbindung zum Zahlungssystem Stripe:</p>
        <ul>
            <li>Azure Architektur/Konzepterstellung auf Basis von Kundenanforderungen</li>
            <li>Entwicklung und Deployment der Azure Functions zum Verarbeiten der Buchungsdaten</li>
            <li>Anbindung der Adobe PDF Services zur Erstellung der Rechnungsdokumente</li>
            <li>Versand der Rechnungen per Exchange und Archivierung in der Dokumentenablage</li>
            <li>Integration des Zahlungsanbieters Stripe zur automatischen Zahlungsabwicklung</li>
        </ul>
    </AccordionTab>,
    <AccordionTab header="Device-Monitoring" className="experience-accordion-tab accordion-break" key="Device-Monitoring">
        <div className="experience-accordion-tab-tags">
            <Tag>Icinga</Tag>
            <Tag>InfluxDB</Tag>
            <Tag>Grafana</Tag>
            <Tag>Linux</Tag>
            <Tag>Infrastruktur</Tag>
            <Tag>Reporting</Tag>
        </div>
        <p>Monitoring von On-Premises Netzwerken von mehreren hundert Geräten durch Icinga:</p>
        <ul>
            <li>Einrichten des Icinga Monitoring Servers inkl. InfluxDB und Grafana</li>
            <li>Einpflegen der einzelnen Geräte und konfigurieren nach bestimmten Gerätegruppen</li>
            <li>Verbindung mit den Geräten durch den Icinga Background Service herstellen und Abfrage der Daten</li>
            <li>Konfiguration von Alamierungen nach Geräte / Serviceausfall per Mail und SMS</li>
            <li>Grafana Dashboard Entwicklung nach der Datenbasis über Abfragen der Datenbank inkl. Langzeit-Reports</li>
        </ul>
    </AccordionTab>,
    <AccordionTab header="Extensions für SharePoint / Teams / PowerPlatform" className="experience-accordion-tab" key="Extensions für SharePoint / Teams / PowerPlatform">
        <div className="experience-accordion-tab-tags">
            <Tag>SharePoint</Tag>
            <Tag>SPFX</Tag>
            <Tag>Teams</Tag>
            <Tag>SPFX</Tag>
            <Tag>PowerPlatform</Tag>
            <Tag>PCF</Tag>
            <Tag>React</Tag>
            <Tag>Typescript</Tag>
            <Tag>Javascript</Tag>
            <Tag>SharePoint Designer</Tag>
            <Tag>Microsoft Graph</Tag>
            <Tag>SharePoint Rest API</Tag>
        </div>
        <p>Projektübergreifende Custom-Entwicklungen in Teams, SharePoint und PowerApps mit React</p>
        <ul>
            <li>Erweiterung von SharePoint Online und On-Premises durch SharePoint-Framework Webparts (SPFX / React)</li>
            <li>Erweiterung von SharePoint On-Premises durch Display-Template Anpassungen per SharePoint Designer</li>
            <li>Erweiterung von Microsoft Teams mit Teams-Apps durch SharePoint-Framework Webparts (SPFX / React)</li>
            <li>Erweiterung von Microsoft PowerApps durch das PowerApps Component Framework (PCF / React)</li>
            <li>Anbindung der Erweiterungen zu verschiedenen Microsoft Diensten mit APIs zu Microsoft Graph, SharePoint, Dynamics etc.</li>
            <li>Anbindung der Erweiterungen zu nicht-Microsoft Diensten mit Abfragen von Feiertagen, Finanzdaten etc.</li>
        </ul>
    </AccordionTab>,
    <AccordionTab header="Newsmanagement" className="experience-accordion-tab accordion-break" key="Newsmanagement">
        <div className="experience-accordion-tab-tags">
            <Tag>React</Tag>
            <Tag>Typescript</Tag>
            <Tag>ASP.NET</Tag>
            <Tag>Azure Functions</Tag>
            <Tag>Azure App Services</Tag>
            <Tag>Azure SQL</Tag>
            <Tag>RSS Parsing</Tag>
            <Tag>Azure API-Management</Tag>
            <Tag>Multi-Tenant Application</Tag>
        </div>
        <p>Entwicklung einer Standalone React WebApps zur Verwaltung von Microsoft News, Roadmaps und Dienststati:</p>
        <ul>
            <li>Architektur der Azure Ressourcen und API/RSS Anbindungen</li>
            <li>Architektur der Datenbank zum Speichern von aktuellen und historischen Daten</li>
            <li>Einrichten der Azure Ressourcen inkl Authentifizierung mit Microsoft Accounts aus unterschiedlichen Tenants</li>
            <li>Entwicklung von Collector-Jobs zur intervallgesteuerten Abfrage der News-Feeds</li>
            <li>Entwicklung einer API als Backend unter Verwendung von ASP.NET</li>
            <li>Entwicklung des Frontends unter Verwendung von React (Typescript)</li>
        </ul>
    </AccordionTab>,
    <AccordionTab header="Kiosk-Software" className="experience-accordion-tab" key="Kiosk-Software">
        <div className="experience-accordion-tab-tags">
            <Tag>Electron</Tag>
            <Tag>Angular</Tag>
            <Tag>Typescript</Tag>
            <Tag>NFC</Tag>
            <Tag>Relais</Tag>
            <Tag>Kiosk-Devices</Tag>
            <Tag>GoCardless</Tag>
        </div>
        <p>Shopsystem eines Essens-/Getränkeautomaten als Kiosk-Software unter Angular:</p>
        <ul>
            <li>Entwicklung der Angular Anwendung zum Kauf am Kiosk-Automaten</li>
            <li>Hosten des Frontends mit Electron auf dem Kiosk-Gerät selbst als Shopsystem</li>
            <li>Integration des Zahlungsanbieters GoCardless an das Backend zur Zahlungsabwicklung</li>
            <li>Konzepterstellung für das Schreiben und Lesen der custom NFC-Karten und der darauf zu speichernden Daten</li>
            <li>Anbindung von NFC Readern an das Shopsystem zum Abwickeln des Bezahlvorgangs</li>
            <li>Anbindung von Relais an das Shopsystem zum Steuern von elektronischen Geräten im Automaten zur Essens- und Getränkeausgabe</li>
        </ul>
    </AccordionTab>
];

export default class Experience extends React.Component<{}, IExperienceState> {

    constructor(props: {}) {
        super(props);

        this.state = {
            selectedTab: 0
        };
    }

    public render() {
        return(
            <div className="experience break-always">
                <div className="experience-headline"><h2>Projekterfahrung</h2></div>
                <Accordion className="experience-accordion noprint">
                    {tabElements}
                </Accordion>
                <Accordion
                    className="experience-accordion print-only"
                    multiple={true}
                    activeIndex={tabElements.map((e, i) => i)}
                    collapseIcon={<></>}
                >
                    {tabElements}
                </Accordion>
                <p style={{textAlign: "center"}}>Und viele weitere Projekte</p>
            </div>
        );
    }

    public componentDidMount(): void {
        //remove anchor links from tab headers on print
        setTimeout(() => {
            let tabHeaderPrintLinks = document.querySelectorAll(".experience-accordion.print-only a.p-accordion-header-link");
            tabHeaderPrintLinks.forEach(link => {
                link.removeAttribute("href");
            });
        }, 500);
    }
}