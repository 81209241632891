import React from 'react';
import './App.scss';
import Introduction from './Introduction';
import SkillCoud from './SkillCloud';
import SkillDescription from './SkillDescription';
import { Skills } from './Skills';
import Timeline from './Timeline';
import { ScrollTop } from 'primereact/scrolltop';
import Documents from './Documents';
import Experience from './Experience';
import { BiDownload, BiSun } from 'react-icons/bi';
import { Tooltip } from 'primereact/tooltip';
import OpenSource from './OpenSource';
import { Card } from 'primereact/card';
import { Rating } from 'primereact/rating';

export interface IAppState {
    selectedSkill: number;
    dark: boolean;
}

export default class App extends React.Component<{}, IAppState> {

    constructor(props: {}) {
        super(props);

        this.state = {
            selectedSkill: 0,
            dark: true
        };
    }

    componentDidMount(): void {
        let theme: string | null = localStorage.getItem("theme");
		if(theme && theme === "light")
			this.changeTheme(false);
    }

    public render() {
        return(
            <div>
                <div className='background' style={{background: `linear-gradient(135deg, var(${this.state.dark ? "--indigo-900" : "--surface-300"}) -75%, var(--surface-ground) 100%)`}}></div>
                <ScrollTop className='noprint' threshold={300}/>
                <div className="topbar noprint">
					<div>
						<div>
                            <Tooltip target=".topbar-item" position='bottom' showDelay={250} />
                            <div onClick={() => this.download()} className="topbar-item" data-pr-tooltip="Download">
								<BiDownload/>
							</div>
							<div onClick={() => this.changeTheme()} className="topbar-item" data-pr-tooltip="Theme">
								<BiSun/>
							</div>
						</div>
					</div>
				</div>
                
                <Introduction/>
                <Timeline/>
                <div className="skill break-always">
                    <div className='skill-headline'><h2>Skills</h2></div>
                    <div className='noprint'>
                        <SkillCoud darkTheme={this.state.dark} skills={Skills} selectIcon={(icon) => this.setState({selectedSkill: icon})} />
                        <SkillDescription skills={Skills} selectedSkill={this.state.selectedSkill} />
                    </div>
                    <div className='print-only'>
                        <div className="skill-content">
                            {Skills.map((skill, index) =>
                                <>
                                    {(index !== 0 && index % 4 === 0) &&
                                        <div className='break-always break-spacer'></div>
                                    }
                                    <Card
                                        key={skill.title}
                                        className="skill-card"
                                        title={skill.title}
                                        subTitle={<div className="skill-card-rating"><span>Skill Level: </span><Rating value={skill.rating || 0} readOnly stars={5} cancel={false} /></div>}>
                                        
                                        <p>{skill.subtitle}</p>
                                        <p>Erfahrung mit:</p>
                                        <ul>
                                            {skill.experience.map(x => <li key={x}>{x}</li>)}
                                        </ul>
                                    </Card>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <Documents/>
                <Experience/>
                <OpenSource/>
            </div>
        );
    }

    private changeTheme(dark: boolean = !this.state.dark) {
		let element = document.getElementById('theme-css');
        if(element) {
            let href = element.getAttribute("href");
            if(href) {
                href = href.substring(0, href.lastIndexOf("/"));
                element.setAttribute("href", href + "/" + (dark ? "dark.css" : "light.css"));
				localStorage.setItem("theme", dark ? "dark" : "light");
                this.setState({
                    dark: dark
                });
				return;
            }
        }
		console.error("Could not set theme properly - Missing html attributes");
	}

    private download(dark: boolean = this.state.dark) {
        let link = document.createElement("a");
        link.href = `/downloads/CV - Julian Effenberger - ${dark ? "Dark" : "Light"}.pdf`;
        link.download = "CV - Julian Effenberger.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

}
