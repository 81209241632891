import React from "react";
import { Card } from 'primereact/card';

export default class Documents extends React.Component<{}, {}> {
    public render() {
        return (
            <div className="documents break-always">
                <div className="documents-headline"><h2><span className="documents-headline-text">Qualifikationen</span></h2></div>
                <div className="documents-content">
                    <Card className="documents-content-card" title="Englisch B2" subTitle="05/2019">
                        Erwerb des <b>KMK-Fremdsprachenzertifikats Englisch</b> auf dem Level B2 in der Berufsschule Obernburg.
                    </Card>
                    <Card className="documents-content-card" title="Ausbildung Fachinformatik" subTitle="02/2020">
                        Abschluss der Ausbildung zum <b>Fachinformatiker Fachrichtung: Anwendungsentwicklung</b> bei der IHK Aschaffenburg.
                    </Card>
                    <Card className="documents-content-card" title="Azure Developer" subTitle="10/2020">
                        Ablegen der Prüfung <a href="https://docs.microsoft.com/en-us/learn/certifications/exams/az-204" rel="noopener noreferrer" target="_blank">AZ-204</a> zum <b>Microsoft Certified: Azure Developer Associate</b>.
                    </Card>
                    <Card className="documents-content-card" title="Power Platform Consultant" subTitle="04/2021">
                        Ablegen der Prüfung <a href="https://docs.microsoft.com/en-us/learn/certifications/exams/pl-200" rel="noopener noreferrer" target="_blank">PL-200</a> zum <b>Microsoft Certified: Power Platform Functional Consultant Associate</b>.
                    </Card>
                    <Card className="documents-content-card" title="Ausbilderschein" subTitle="05/2021">
                        Bestehen der <b>Ausbilder-Eignungsprüfung</b> bei der IHK Ulm.
                    </Card>
                    <Card className="documents-content-card" title="Identity Administrator" subTitle="12/2022">
                    Ablegen der Prüfung <a href="https://docs.microsoft.com/en-us/learn/certifications/exams/sc-300" rel="noopener noreferrer" target="_blank">SC-300</a> zum <b>Microsoft Certified: Identity and Access Administrator</b>
                    </Card>
                </div>
            </div>
        );
    }
}