import { SimpleIcon } from "simple-icons";
import { siAmazondynamodb, siAzuredevops, siCsharp, siElectron, siJavascript, siPhp, siMicrosoftazure, siMicrosoftsharepoint, siPowerapps, siPowershell, siReact, siCplusplus } from "simple-icons/icons";


export interface ISkill {
    icon: SimpleIcon;
    title: string;
    subtitle: string,
    rating: number,
    experience: string[]
}

export const Skills: ISkill[] = [
        {
            icon: siMicrosoftazure,
            title: "Microsoft Azure",
            subtitle: "Archtiektur, Entwicklung und Implementierung von Microservices in der Cloud",
            rating: 5,
            experience: [
                "App Services und Function Apps",
                "Datenbanken (SQL, Cosmos DB, Storage Account)",
                "Data Factories",
                "API Management",
                "Logic Apps",
                "Event Hubs",
                "IoT Hubs",
                "Cognitive Services",
                "Log Analytics und Monitoring",
                "Active Directory",
                "Application Lifecycle Management",
            ]
        },
        {
            icon: siReact,
            title: "React",
            subtitle: "Frontend Library für Standalone WebApps oder Erweiterungen in SharePoint, Teams und PowerApps",
            rating: 5,
            experience: [
                "Standalone WebApps",
                "Erweiterung von SharePoint und Microsoft Teams mit SPFX",
                "Erweiterung von PowerApps mit PCF",
                "Entwicklung mit Hooks und Class Components",
                "Anbindung externer Tools und APIs inkl Authentifizierung und Multi-Tenant Apps",
            ]
        },
        {
            icon: siCsharp,
            title: "C#",
            subtitle: ".NET Core, .NET Framework und APIs mit ASP.NET",
            rating: 4,
            experience: [
                "Azure Functions",
                "Azure App Services",
                "Integration von SharePoint CSOM und PnP",
                "Integration von Microsoft 365 Tools mit Graph",
                "Integration von Azure Ressourcen",
                "Integration diverser Platformen (z.B. Google Cloud oder Adobe PDF Services)",
            ]
        },
        {
            icon: siJavascript,
            title: "Basic Frontend Stack",
            subtitle: "HTML, CSS, SASS, JavaScript, Typescript, ... - als Basis für das Frontend in nahezu allen Projekten",
            rating: 4,
            experience: [
                "React Apps und Components",
                "Angular Apps",
                "SharePoint DisplayTemplates",
                "Vorlagen für den PDF-Druck",
                "Vorlagen für den Mail-Versand",
                "Laravel Apps",
                "Wordpress Seiten und Plugins",
            ]
        },
        {
            icon: siPowershell,
            title: "Powershell",
            subtitle: "Powershell für automatisierte Scripts in der Cloud oder der Verwaltung von On-Premises Servern und Cloud Services ohne GUI",
            rating: 4,
            experience: [
                "Automatische Ausführung von Scripten in der Cloud",
                "Verwaltung von Microsoft 365 Cloud-Services (SharePoint, Exchange, Teams, Azure, etc.)",
                "Verwaltung von On-Premises Servern (SharePoint, Exchange, Active Directory, PowerBI)",
                "Abindung von Daten aus Datenbanken, Excel Dateien und Dateisystemen",
                "Anpassen von Windows-Komponenten auf Clients und Servern",
            ]
        },
        {
            icon: siMicrosoftsharepoint,
            title: "SharePoint",
            subtitle: "SharePoint als zentrales System und Grundlage für Firmenintranets in der Microsoft 365 Plattform",
            rating: 4,
            experience: [
                "Erweiterung von SharePoint On-Premises durch Anpassung von Display Templates",
                "Erweiterung von SharePoint und Microsoft Teams durch SPFX-Webparts und Extensions",
                "Einbettung von Microsoft 365 Tools per Graph- und REST-APIs",
                "Konfiguration und Verwaltung von Sites, Sitecollections und Tenants (Cloud) bzw. Servern (OnPrem)",
                "Migration von Server- und Tenantumgebungen",
            ]
        },
        {
            icon: siAmazondynamodb,
            title: "Datenbanken",
            subtitle: "Datenbanken / Datenspeicher im Hintergrund von entwickelten Custom-Apps",
            rating: 4,
            experience: [
                "Azure Storage Accounts und Data Lake",
                "Azure SQL",
                "Cosmos DB",
                "InfluxDB",
                "MySQL",
                "Redis",
            ]
        },
        {
            icon: siPhp,
            title: "Php",
            subtitle: "Web-Apps und APIs entweder ganz ohne Framework oder unter Verwendung von Wordpress, Laravel und Lumen im Zusammenspiel mit Datenbanken",
            rating: 3,
            experience: [
                "Entwicklung von WebApps ohne Framework",
                "Entwicklung von WebApps mit Laravel",
                "Entwicklung von APIs mit Lumen",
                "Anpassung und Entwicklung von Wordpress Plugins",
                "Konfigurieren von WebApps und APIs in Shared Webhosting Umgebungen",
            ]
        },
        {
            icon: siAzuredevops,
            title: "Quellcodeverwaltung",
            subtitle: "Azure DevOps und Github nicht nur zur Ablage für jegliche Scripte und Programme, sondern mit Pipelines und Workflows auch für automatische Deployments",
            rating: 3,
            experience: [
                "Repositories zur Quellcodeablage",
                "Boards zur Projekt und Aufgabenplanung",
                "Pipelines zum automatischen Build der Anwendungen",
                "Pipelines zum automatischen Deployment der Anwendungen",
            ]
        },
        {
            icon: siPowerapps,
            title: "PowerPlatform",
            subtitle: "PowerAutomate, PowerApps und PowerBI zur schnellen Entwicklung von Business-Apps und automatisierten Prozessen",
            rating: 3,
            experience: [
                "Kundenberatung im Bereich der PowerPlatform-Tools",
                "Erweiterung von PowerApps mit dem PowerApps Component Framework (PCF)",
                "Anbindung von Daten an PowerAutomate, PowerApps und PowerBI mit Custom Connectors",
                "Application Lifecycle Management",
            ]
        },
        // {
        //     icon: siIcinga,
        //     title: "Monitoring",
        //     subtitle: "Abrufen und Auswerten von Server- und Service-Stati",
        //     rating: 3,
        //     experience: [
        //         "Installation und Konfiguration von Icinga-Servern und Clients",
        //         "Konfiguration von InfluxDB und Speichern/Abrufen von Daten",
        //         "Konfiguration und Design von Grafana-Dashboards",
        //         "Konfiguration von Azure Log Analytics Workspaces und Anbindung diverser Azure-Services",
        //         "Eskalationsprozesse in Icinga und Azure mit Benachrichtigung per Mail oder SMS",
        //         "Anbindung von Monitoring Daten an PowerBI",
        //     ]
        // },
        {
            icon: siElectron,
            title: "Electron",
            subtitle: "Integration von Web-Entwicklungen in Desktop-Apps zur lokalen Ausführung auf Clients oder Kiosk-Geräten",
            rating: 2,
            experience: [
                "Integration und Konfiguration von React und Angular Apps in Electron",
                "Integration von Hardware-Schnittstellen zu bspw. Relais und NFC-Readern",
                "Automatisierte Build und Deployment-Prozesse",
                "Implementierung automatisierter Updates",
            ]
        },
        {
            icon: siCplusplus,
            title: "C / C++",
            subtitle: "(Embedded) C / C++ zur Entwicklung von IoT-Firmware",
            rating: 2,
            experience: [
                "Firmwareentwicklung per Arduino-Framework (Atmega und ESP32)",
                "Kernelentwicklung per Circle-Framework (Raspberry Pi)",
                "Anbindung von BoschXDK-Sensoren an die Azure-Cloud",
                "Anbindung von USB Sensoren an Kiosk-Apps",
            ]
        }
]