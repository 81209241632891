import React from "react";
import { Tag } from 'primereact/tag';
import { ImGithub, ImLinkedin } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import { FaGlobe } from "react-icons/fa";

export default class introduction extends React.Component<{}, {}> {
    public render() {
        return (
            <div className="overview">
                <div className="overview-image"><img src="./profile-small.webp" alt="Julian Effenberger" srcSet="./profile-full.webp 4x, ./profile-medium.webp 2x, ./profile-small.webp 1x" /></div>
                <div className="overview-description">
                    <h1>Julian Effenberger</h1>
                    <div>
                        <Tag className="overview-tag" value="Developer" icon="pi pi-code" />
                        <Tag className="overview-tag" value="Architect" icon="pi pi-sitemap" />
                        <Tag className="overview-tag" value="Consultant" icon="pi pi-user" />
                        <Tag className="overview-tag" value="Trainer" icon="pi pi-comment" />
                    </div>
                    <div className="overview-keyfacts">
                        <b>Keyfacts</b>
                        <ul>
                            <li>Technikbegeistert.</li>
                            <li>Ledig.</li>
                            <li>Schlagzeuger.</li>
                        </ul>
                        <b>Contacts</b>
                        <ul style={{listStyleType: "none", marginLeft: "-18px"}}>
                            <li><a target="_blank" rel="noopener noreferrer" href="mailto:julian@julianeffenberger.de"><MdEmail/>&ensp;<span>julian@julianeffenberger.de</span></a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/effenberger-julian-aburg"><ImLinkedin/>&ensp;<span>Linkedin</span></a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://www.github.com/DerEffi"><ImGithub/>&ensp;<span>Github</span></a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://www.dereffi.de"><FaGlobe/>&ensp;<span>DerEffi</span></a></li>
                        </ul>
                    </div>
                </div>
                <div className="overview-keyfacts overview-online print-only">
                    <ul style={{listStyleType: "none", padding: 0, textAlign: "center"}}>
                        <li>
                            <a target="_blank" rel="noopener noreferrer" href="https://cv.julianeffenberger.de"><span>Onlineversion (interaktiv)</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}