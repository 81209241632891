import React from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Tag } from 'primereact/tag';
import { ImGithub } from "react-icons/im";
import { TfiLayoutGrid4Alt } from 'react-icons/tfi';

export interface IOpenSourceState {
    selectedTab: number;
}

const tabElements: JSX.Element[] = [
    <AccordionTab header="Pixelart Display" className="experience-accordion-tab" key="Pixelart Display">
        <div className="experience-accordion-tab-tags">
            <Tag>LED Matrix</Tag>
            <Tag>HUB75E</Tag>
            <Tag>ESP32</Tag>
            <Tag>Arduino</Tag>
            <Tag>C / C++</Tag>
            <Tag>React</Tag>
            <Tag>Laravel</Tag>
            <Tag>RESTful API</Tag>
            <Tag>Social Media APIs</Tag>
        </div>
        <p className="icon-link">
            <a target="_blank" rel="noopener noreferrer" href="http://pixelart.dereffi.de/"><TfiLayoutGrid4Alt/>&ensp;<span>Project Site</span></a>
            <br/>
            <a target="_blank" rel="noopener noreferrer" href="https://github.com/DerEffi/pixelart"><ImGithub/>&ensp;<span>Source Code</span></a>
        </p>
        <p>Display zum Darstellen von Bildern, Uhrzeit Social Media Stats auf einer 64x64 LED Matrix:</p>
        <ul>
            <li>Darstellen von Bildern auf der LED Matrix</li>
            <li>Darstellen der aktuellen Uhrzeit mittels RTC-Modul und NTP-Server</li>
            <li>Steuerung mittels On-Device Buttons</li>
            <li>RESTful API mit Webinterface zur remote Konfiguration über WLAN</li>
            <li>Abfrage aktueller Social-Media Kennzahlen per Proxy-Service</li>
            <li>OTA Updates per SD-Karte oder das Webinterface vom Updateserver</li>
        </ul>
    </AccordionTab>,
    <AccordionTab header="Image Comparison" className="experience-accordion-tab" key="Image Comparison">
        <div className="experience-accordion-tab-tags">
            <Tag>.NET</Tag>
            <Tag>WPF</Tag>
            <Tag>AvaloniaUI</Tag>
            <Tag>Image Hashing</Tag>
            <Tag>Image Caching</Tag>
            <Tag>Image Comparison</Tag>
        </div>
        <p className="icon-link"><a target="_blank" rel="noopener noreferrer" href="https://github.com/DerEffi/image-comparison"><ImGithub/>&ensp;<span>Source Code</span></a></p>
        <p>Massenvergleich von Bildern zwischen oder innerhalb verschiedener Ordner zur Suche von Duplikaten oder Ähnlichkeiten:</p>
        <ul>
            <li>Bilderanalyse basierend auf Edge-Detection statt auf Pixelbasis</li>
            <li>Vorschau der gefundenen Übereinstimmungen mit Detailansicht und Ähnlichkeitsgrad</li>
            <li>Löschen der Bilder in einen Ordner, den Papierkorb oder permanent</li>
            <li>Cachen der Analysedaten für zukünftige Scans</li>
            <li>Cachen der No-Matches für zukünftige Scans</li>
            <li>Verschiedene Suchmodi zwischen Bildern innerhalb oder zwischen verschiedenen Ordnern</li>
            <li>Verschiedene Hashfunktionen (Difference/Perceptual)</li>
            <li>Variabler Detailgrad bei der Hasherstellung</li>
            <li>Automatische Verarbeitung von ähnlichen Bildern nach anpassbaren Regeln</li>
            <li>Speicherbare Suchprofile</li>
            <li>Command Line Interface</li>
        </ul>
    </AccordionTab>
];

export default class OpenSource extends React.Component<{}, IOpenSourceState> {

    constructor(props: {}) {
        super(props);

        this.state = {
            selectedTab: 0
        };
    }

    public render() {
        return(
            <div className="experience break-always">
                <div className="experience-headline"><h2>Open Source Projekte</h2></div>
                <Accordion className="experience-accordion noprint">
                    {tabElements}
                </Accordion>
                <Accordion
                    className="experience-accordion print-only"
                    multiple={true}
                    activeIndex={tabElements.map((e, i) => i)}
                    collapseIcon={<></>}
                >
                    {tabElements}
                </Accordion>
            </div>
        );
    }

    public componentDidMount(): void {
        //remove anchor links from tab headers on print
        setTimeout(() => {
            let tabHeaderPrintLinks = document.querySelectorAll(".experience-accordion.print-only a.p-accordion-header-link");
            tabHeaderPrintLinks.forEach(link => {
                link.removeAttribute("href");
            });
        }, 500)
    }
}