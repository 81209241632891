import React from "react";
import { VerticalTimeline, VerticalTimelineElement, VerticalTimelineElementProps }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { IoSchool, IoCodeSlash } from "react-icons/io5";
import { ImOffice } from "react-icons/im";
import { FaUniversity } from "react-icons/fa";
import { GiSchoolBag } from "react-icons/gi";

const timelineElementStyle: VerticalTimelineElementProps = {
    className: "nobreak timeline-element",
    contentStyle: {
        background: "var(--surface-d)",
        color: "var(--text-color)",
        boxShadow: "none",
        fontSize: "1rem !important"
    },
    contentArrowStyle: {
        borderRight: "7px solid var(--surface-d)",
    },
    iconStyle: {
        background: "var(--primary-color)",
        color: "var(--primary-color-text)",
        boxShadow: "0 0 0 8px var(--surface-ground),inset 0 2px 0 rgba(0,0,0,.08),0 3px 0 4px rgba(0,0,0,.05)",
    },
    iconClassName: "timelineIcon"
}

const timelineElements: React.ReactNode = <>
    <VerticalTimelineElement
        {...timelineElementStyle}
        icon={<GiSchoolBag/>}
        date="2009 - 2017"
    >
        <h3>Allgemeine Hochschulreife</h3>
        <p>Erwerb der allgemeinen Hochschulreife im <a href="https://franziskanergymnasium-kreuzburg.de" target="_blank" rel="noopener noreferrer">Franziskanergymnasium Kreuzburg</a> in Gro&szlig;krotzenburg nach der 12. Klasse.</p>
    </VerticalTimelineElement>
    <VerticalTimelineElement
        {...timelineElementStyle}
        icon={<IoSchool/>}
        date="2017 - 2020"
    >
        <h3>Ausbildung - Fachinformatik</h3>
        <p>Berufsausbildung zum Fachinformatiker für Anwendungsentwicklung bei der <a href="https://kom4tec.de" target="_blank" rel="noopener noreferrer">KOM4TEC GmbH</a> in Aschaffenburg mit erfolgreichem Abschluss nach 2,5 Jahren.</p>
    </VerticalTimelineElement>
    <VerticalTimelineElement
        {...timelineElementStyle}
        icon={<IoCodeSlash/>}
        date="2019 - 2022"
    >
        <h3>Development - Dinehub</h3>
        <p>Freiberufliche Nebentätigkeit bei <a href="https://qntmvis.com" target="_blank" rel="noopener noreferrer">QuantumVis</a> zur Konzeptionierung und Implementation eines maßgeschneiderten Warenwirtschafts und Shopsystems in der Azure Cloud inkl. Integration von Kiosk-Geräten mit angepasster Hardware.</p>
    </VerticalTimelineElement>
    <VerticalTimelineElement
        {...timelineElementStyle}
        icon={<ImOffice/>}
        date="2020 - 2022"
    >
        <h3>Consulting/Architecture/Training - Microsoft 365</h3>
        <p>Beratung, Konzeptionierung und Umsetzung diverser Projekte im Bereich Microsoft Azure / Microsoft 365 nach Übernahme bei <a href="https://kom4tec.de" target="_blank" rel="noopener noreferrer">KOM4TEC</a> mit den Schwerpunkten Cloud Architektur, API Implementation und Kommunikation verschiedener Systeme. Darunter zum Beispiel Anbindung von Finanzsystemen, Rechnungserstellung, Data Warehouse, M365 Migrationen und Prozessautomatisierung.</p>
        <p>Organisation der Ausbildungsinhalte, Betreuung der Auszubildenden als Ansprechpartner und Vermitteln von Ausbildungsinhalten im Bereich Cloud Development.</p>
    </VerticalTimelineElement>
    <VerticalTimelineElement
        {...timelineElementStyle}
        icon={<FaUniversity/>}
        date="2021 - heute"
    >
        <h3>Studium - Informatik</h3>
        <p>Studium zum Bachelor of Science im Studiengang Informatik an der <a href="https://www.tu-darmstadt.de" target="_blank" rel="noopener noreferrer">Technischen Universität Darmstadt</a>.</p>
    </VerticalTimelineElement>
</>;

export default class Timeline extends React.Component<{}, {}> {

    public render() {
        return(
            <>
                <VerticalTimeline
                    className="noprint"
                    animate={true}
                    layout="2-columns"
                    lineColor="var(--primary-color)"
                >
                    {timelineElements}
                </VerticalTimeline>

                <VerticalTimeline
                    className="print-only break-always"
                    animate={false}
                    layout="1-column"
                    lineColor="var(--primary-color)"
                >
                    {timelineElements}
                </VerticalTimeline>
            </>
        );
    }

}