import React from "react";
import { Cloud, renderSimpleIcon } from "react-icon-cloud";
import { ISkill } from "./Skills";

export interface ISkillCloudProps {
    selectIcon: (icon: number) => void;
    skills: ISkill[];
    darkTheme: boolean;
}

export default class SkillCoud extends React.Component<ISkillCloudProps, {}> {
    public render() {
        return(
            <div className="skill-cloud">
                <Cloud
                    options={{
                        tooltipDelay: 0,
                        clickToFront: 250,
                        depth: .75,
                        outlineColour: "transparent",
                        reverse: true,
                        tooltip: null,
                        wheelZoom: false,
                        imageScale: 1.25,
                        maxSpeed: .05
                    }}
                >
                    {
                        this.props.skills.map((skill, index) => renderSimpleIcon({
                            icon: skill.icon,
                            size: 56,
                            fallbackHex: this.props.darkTheme ? "#FFF" : "#121212",
                            minContrastRatio: 22,
                            aProps: {
                                onClick: (e: any) => {
                                    e.preventDefault();
                                    this.props.selectIcon(index);
                                }
                            }
                        }))
                    }
                </Cloud>
            </div>
        );
    }

    public shouldComponentUpdate(nextProps: Readonly<ISkillCloudProps>, nextState: Readonly<{}>) {
        return this.props.darkTheme !== nextProps.darkTheme;
    }
}